/*
  Author: Sreenivas Doosa
*/

import _ from 'lodash';
import async from 'async';
import React from 'react';
import {
  Table,
  Card,
  CardBody,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Progress,
  UncontrolledAlert,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';

import HttpRequest from "request";
import config from "../../config.js";

import Utils from "../../utils/Utils.js";

import WebSocketClient from '../common/WebSocketClient.js';
import TradesSummaryComp from '../trades/TradesSummaryComp.js';
import TradesModal from './TradesModal.js';
import ComparePositionsModal from './ComparePositionsModal.js';
import SquareOffModal from '../common/SquareOffModal.js';
import AlterTradesBulkModal from '../common/AlterTradesBulkModal.js';
import ConfirmationModal from '../common/ConfirmationModal.js';
import { fetchAllocationModels } from '../../utils/RestAPIs.js';

const riskDistances = [-10, -5, -3, -1, 0, 1, 3, 5, 10];

class UsersAllTradesStatus extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showIntraday: true,
      showPositional: true,
      showCashBuy: false,
      allocationModels: [],
      users: [],
      usersBrokersList: [],
      userBrokerToStrategiesMap: {},
      strategiesSummary: {},
      userBrokerToTradesMap: {},
      fetchingUsersInProgress: false,
      error: null,
      showTradesModal: false,
      fetchingUserTradesInProgress: {},
      riskProfileWrtCmp: Utils.isMarketOpen() ? false : true
    };

    this.socket = new WebSocketClient('UsersAllTradesStatus');

    this.toggleIntraday = this.toggleIntraday.bind(this);
    this.togglePositional = this.togglePositional.bind(this);
    this.toggleCashBuy = this.toggleCashBuy.bind(this);
    this.toggleRiskProfileWrtCmp = this.toggleRiskProfileWrtCmp.bind(this);

    this.launchTradesModal = this.launchTradesModal.bind(this);
    this.launchComparePositionsModal = this.launchComparePositionsModal.bind(this);
    this.launchSquareOffModal = this.launchSquareOffModal.bind(this);
    this.launchBulkSquareOffConfirmationModal = this.launchBulkSquareOffConfirmationModal.bind(this);
    this.launchUserPnlModal = this.launchUserPnlModal.bind(this);

    this.closeTradesModal = this.closeTradesModal.bind(this);
    this.closeComparePositionsModal = this.closeComparePositionsModal.bind(this);
    this.closeSquareOffModal = this.closeSquareOffModal.bind(this);
    this.closeBulkSquareOffConfirmationModal = this.closeBulkSquareOffConfirmationModal.bind(this);
    this.closeUserPnlModal = this.closeUserPnlModal.bind(this);

    this.renderRiskPopover = this.renderRiskPopover.bind(this);
    this.toggleRiskPopover = this.toggleRiskPopover.bind(this);

    this.refreshUserTrades = this.refreshUserTrades.bind(this);

    this.onOrderUpdate = this.onOrderUpdate.bind(this);
    this.onPositionUpdate = this.onPositionUpdate.bind(this);

    this.sharedUsernames = [];
    this.onDemandRefreshUserTradesTimer = {};
  }

  prepareUsersBrokersList(users) {
    let usersBrokersList = [];
    _.each(users, user => {
      _.each(user.brokers, ubd => {
        if (user.enabled && ubd.enabled) {
          usersBrokersList.push({
            username: user.username,
            broker: ubd.broker,
            clientID: ubd.clientID,
            allocationModel: ubd.allocationModel
          });
        }
        if (ubd.useApiOf) {
          if (_.includes(this.sharedUsernames, ubd.useApiOf) === false) {
            this.sharedUsernames.push(ubd.useApiOf);
          }
        }
      });
    });
    // filter out shared usernames in which trading does not happen
    // usersBrokersList = _.filter(usersBrokersList, ub => _.includes(this.sharedUsernames, ub.username) === false);
    
    // sort by total capital in descending order
    usersBrokersList.sort((a, b) => this.getTotalCapitalOfUserBroker(b.username, b.broker) - this.getTotalCapitalOfUserBroker(a.username, a.broker));
    return usersBrokersList;
  }

  getUserBrokerAllocationModel(username, broker) {
    return (_.find(this.state.usersBrokersList, ub => ub.username === username && ub.broker === broker) || {}).allocationModel;
  }

  getUserBrokerTrades(username, broker) {
    const filterTrades = (trades) => {
      return _.filter(trades, trade => {
        return (this.state.showIntraday && trade.product === 'INTRADAY') ||
          (this.state.showPositional && trade.product === 'POSITIONAL') ||
          (this.state.showCashBuy && trade.product === 'CASHBUY');
      });
    }
    const filterPositions = (positions) => {
      return _.filter(positions, pos => {
        return (this.state.showIntraday && pos.productType === 'MIS') ||
          (this.state.showPositional && pos.productType === 'NRML') ||
          (this.state.showCashBuy && pos.productType === 'CNC');
      });
    }
    const key = username + '-' + broker;
    const allUserTrades = this.state.userBrokerToTradesMap[key] || {};
    return {
      activeTrades: filterTrades(allUserTrades.activeTrades),
      completedTrades: filterTrades(allUserTrades.completedTrades),
      openTrades: filterTrades(allUserTrades.openTrades),
      cancelledTrades: filterTrades(allUserTrades.cancelledTrades),
      trackLostTrades: filterTrades(allUserTrades.trackLostTrades),
      algoPositions: filterPositions(allUserTrades.algoPositions),
      brokerPositions: filterPositions(allUserTrades.brokerPositions)
    };
  }

  getUserBrokerStrategies(username, broker) {
    const key = username + '-' + broker;
    return _.filter(this.state.userBrokerToStrategiesMap[key], ubs => {
      if (ubs.product.startsWith('CAPITAL')) {
        if (ubs.strategyName === 'CAPITAL-DEPLOYED') {
          return true;
        }
        if ((ubs.strategyName === 'CAPITAL-EXTERNAL-INTRA' && this.state.showIntraday) ||
          (ubs.strategyName === 'CAPITAL-EXTERNAL-POS' && this.state.showPositional))
        return true;
      }
      if ((this.state.showIntraday && ubs.product === 'INTRADAY') ||
        (this.state.showPositional && ubs.product === 'POSITIONAL') ||
        (this.state.showCashBuy && ubs.product === 'CASHBUY')) {
        return true;
      }
      return false;
    });
  }

  isAllStrategiesDisabled(username, broker) {
    const key = username + '-' + broker;
    const strategies = this.state.userBrokerToStrategiesMap[key];
    return _.filter(strategies, s => s.enabled).length === 0;
  }

  componentWillMount() {
    this.setState({
      fetchingUsersInProgress: true,
      error: null
    });

    this.socket.addOrderUpdateCallback(this.onOrderUpdate);
    this.socket.addPositionUpdateCallback(this.onPositionUpdate);
    this.socket.connect();

    this.fetchAllocationModels().then(respAllocationModels => {
      this.fetchUsersDetails().then(users => {
        // console.log('Number of users fetched = ' + users.length);
        // prepare userBrokerToStrategiesMap
        const userBrokerToStrategiesMap = {};
        const strategiesSummary = {};
        _.each(users, user => {
          _.each(user.strategies, ubs => {
            const key = user.username + '-' + ubs.broker;
            const ubd = _.find(user.brokers, b => b.broker === ubs.broker);
            const allocationModel = _.find(this.state.allocationModels, am => am.name === ubd.allocationModel);
            if (user.enabled) {
              if (!userBrokerToStrategiesMap[key]) {
                userBrokerToStrategiesMap[key] = [];
              }
              userBrokerToStrategiesMap[key].push({
                product: ubs.product,
                enabled: ubs.enabled,
                strategyName: ubs.strategyName,
                capital: ubs.capital,
                lots: ubs.lots,
                isOverlapCapital: ubs.isOverlapCapital
              });
  
              if (!strategiesSummary[ubs.strategyName]) {
                strategiesSummary[ubs.strategyName] = {
                  product: ubs.product,
                  strategyName: ubs.strategyName,
                  capital: 0,
                  intradayCapital: 0,
                  positionalCapital: 0
                };
              }
              if (ubs.enabled) {
                strategiesSummary[ubs.strategyName].capital += ubs.capital;
                if (ubs.strategyName === 'CAPITAL-DEPLOYED' && allocationModel) {
                  const numSets = ubs.capital / allocationModel.capital;
                  strategiesSummary[ubs.strategyName].intradayCapital += (numSets * allocationModel.intradayCapital);
                  strategiesSummary[ubs.strategyName].positionalCapital += (numSets * allocationModel.positionalCapital);
                }
              }
            }
          });
        });
  
        this.setState({
          users,
          userBrokerToStrategiesMap,
          strategiesSummary,
          fetchingUsersInProgress: false
        }, () => {
          
          const usersBrokersList = this.prepareUsersBrokersList(users);
          // const usersBrokersList = this.prepareUsersBrokersList(users).slice(0, 5);
          this.setState({
            usersBrokersList
          }, () => {
            this.refreshUserTradesAsync(false);
          });
        });
  
      }).catch(err => {
        this.setState({
          users: [],
          usersBrokersList: [],
          userBrokerToTradesMap: {},
          fetchingUsersInProgress: false,
          error: 'Failed to fetch list of users'
        });
      });
    });
  }

  componentWillUnmount() {
    this.stopRefreshUserTradesTimer();
    this.stopRefreshDataRealTimeTimer();
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  fetchAllocationModels() {
    this.setState({
      fetchingDataInProgress: true,
      error: null
    });
    return fetchAllocationModels().then(allocationModels => {
      this.setState({
        allocationModels: allocationModels || [],
        fetchingDataInProgress: false
      });
      return allocationModels;
    }).catch(err => {
      this.setState({
        allocationModels: [],
        fetchingDataInProgress: false,
        error: err || 'Failed to fetch allocation models'
      });
      return err;
    });
  }

  refreshDataRealTime() {
    const userBrokerToTradesMap = {...this.state.userBrokerToTradesMap};
    _.each(userBrokerToTradesMap, (allUserTrades, key) => {
      allUserTrades.activeTrades = _.map(allUserTrades.activeTrades, trade => {
        const latestCMP = this.socket.getCMP(trade.tradingSymbol);
        let updatedTrade = trade;
        if (latestCMP > 0) {
          updatedTrade = {...trade, cmp: latestCMP};
          return Utils.calculateProfitLossCharges(updatedTrade, true);
        }
        return trade;
      });
      allUserTrades.algoPositions = _.map(allUserTrades.algoPositions, pos => {
        const latestCMP = this.socket.getCMP(pos.tradingSymbol);
        pos.cmp = latestCMP > 0 ? latestCMP : pos.cmp;
        return Utils.calculatePositionPnl(pos, latestCMP);
      });
      allUserTrades.brokerPositions = _.map(allUserTrades.brokerPositions, pos => {
        const latestCMP = this.socket.getCMP(pos.tradingSymbol);
        pos.cmp = latestCMP > 0 ? latestCMP : pos.cmp;
        return Utils.calculatePositionPnl(pos, latestCMP);
      });
      userBrokerToTradesMap[key] = {...allUserTrades};
    });
    this.setState({
      userBrokerToTradesMap
    });
  }

  startRefreshDataRealTimeTimer() {
    this.refreshDataRealTimeTimer = setTimeout(() => {
      this.refreshDataRealTimeTimer = null;
      this.refreshDataRealTime();
      this.startRefreshDataRealTimeTimer();
    }, 1000);
  }

  stopRefreshDataRealTimeTimer() {
    if (this.refreshDataRealTimeTimer) {
      clearTimeout(this.refreshDataRealTimeTimer);
      this.refreshDataRealTimeTimer = null;
    }
  }

  getRefreshIntervalInSeconds() {
    let interval = 5 * 60; // default 5 minutes during off market hours
    if (Utils.isMarketOpen()) {
      interval = 120; // 120 seconds
      const time3PM = Utils.getTimeOfToday(15, 0, 0);
      const now = new Date();
      if (now > time3PM) { // between 3 and 3.30 PM
        interval = 60; // 60 seconds
      }
    }
    return interval;
  }

  startRefreshUserTradesTimer() {
    this.refreshTimer = setTimeout(() => {
      this.refreshTimer = null;
      this.refreshUserTradesAsync();
      this.startRefreshUserTradesTimer(); 
    }, this.getRefreshIntervalInSeconds() * 1000);
  }

  stopRefreshUserTradesTimer() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  refreshUserTradesAsync(fetchBrokerPos = true) {
    const tasks = [];
    _.each(this.state.usersBrokersList, ub => {
      tasks.push(cb => {
        this.refreshUserTrades(ub.username, ub.broker, fetchBrokerPos).then(result => {
          cb(null, {result, error: null});
        }).catch(err => {
          cb(null, {error: err})
        });
      });
    });

    async.parallelLimit(tasks, 10, (error, results) => {
      if (error) {
        console.error('refreshUserTradesAsync: error: ', error);
        return;
      }
      console.log(new Date() + ' | refreshUserTradesAsync: Done');
    });
  }

  fetchUsersDetails() {
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + "/apis/clientmanager/users", { json: true }, (err, resp, respBody) => {
        if (err) {
          console.error('fetchUsersDetails: error = ', err);
          return reject(err);
        }
        if (resp) {
          //console.log('fetchUsersDetails: resp.statusCode = ' + resp.statusCode);
          if (resp.statusCode === 200) {
            let users = respBody || [];
            // sort users based on activation date
            users.sort((u1, u2) => {
              return u1.activationDate - u2.activationDate;
            });
            resolve(users);

          } else {
            reject(`fetchUsersDetails: ` + (respBody.error || 'Failed to fetch users'));
          }
        } else {
          reject(`fetchUsersDetails: No error and no response`);
        }
      });
    });
  }

  fetchUserAllTrades(username, broker, fetchBrokerPos = false, force = false) {
    const defaultData = {
      username,
      broker,
      trades: {
        error: null,
        activeTrades: [],
        completedTrades: [],
        openTrades: [],
        cancelledTrades: [],
        trackLostTrades: [],
        algoPositions: [],
        brokerPositions: []
      }
    };
    return new Promise((resolve, reject) => {
      HttpRequest(config.serverHost + `/apis/trades/all?username=${username}&broker=${broker}&fetchbrokerpos=${fetchBrokerPos}&force=${force}`, { json: true }, (err, resp, respBody) => {
        if (err) {
          console.error(`fetchUserAllTrades:${username}-${broker}: error = `, err);
          defaultData.trades = {
            ...defaultData.trades,
            error: 'Unknown error'
          };
          resolve(defaultData); // resolve with default
        } else {
          if (resp) {
            //console.log(`fetchUserAllTrades:${username}-${broker}: resp.statusCode = ` + resp.statusCode);
            if (resp.statusCode === 200) {
              const trades = respBody || {};
              trades.brokerPositions = _.filter(trades.brokerPositions, pos => pos.productType !== 'CNC');
              resolve({
                username,
                broker,
                trades
              });
            } else {
              defaultData.trades = {
                ...defaultData.trades,
                error: 'Status: ' + resp.statusCode
              };
              respBody = respBody || {};
              console.error(`fetchUserAllTrades:${username}-${broker}: ` + (respBody.error || 'Failed to fetch user trades'));
              resolve(defaultData); // resolve with default
            }
          } else {
            defaultData.trades = {
              ...defaultData.trades,
              error: 'No response'
            };
            console.error(`fetchUserAllTrades:${username}-${broker}: No error and no response`);
            resolve(defaultData); // resolve with default
          }
        }
      });
    });
  }

  refreshUserTrades(username, broker, fetchBrokerPos = false, force = false) {
    const key = username + '-' + broker;
    console.log(new Date() + ' | refreshUserTrades called for ' + username + '-' + broker);
    const fetchingUserTradesInProgress = {...this.state.fetchingUserTradesInProgress};
    fetchingUserTradesInProgress[key] = true;
    this.setState({
      fetchingUserTradesInProgress
    });
    return this.fetchUserAllTrades(username, broker, fetchBrokerPos, force).then(result => {
      const userBrokerToTradesMap = {...this.state.userBrokerToTradesMap};
      const fetchingUserTradesInProgress = {...this.state.fetchingUserTradesInProgress};

      userBrokerToTradesMap[key] = result.trades;
      fetchingUserTradesInProgress[key] = false;
      
      this.setState({
        userBrokerToTradesMap,
        fetchingUserTradesInProgress
      });

      return result;
    });
  }

  onOrderUpdate(order = {}) {
    // No need to refresh on order complete status as it is covered by position update
    if (order.username && order.broker) {
      const key = order.username + '-' + order.broker;
      if (!this.onDemandRefreshUserTradesTimer[key]) {
        // Delaying the refresh operation to 3 seconds in order to control the flood of many updates within a second.
        console.log(new Date() + ' | OrderUpdate: scheduling refresh user trades for ' + key);
        this.onDemandRefreshUserTradesTimer[key] = setTimeout(() => {
          this.onDemandRefreshUserTradesTimer[key] = null;
          this.refreshUserTrades(order.username, order.broker, true);
        }, 3000);
      } else {
        console.log(new Date() + ' | OrderUpdate: ignoring refresh as its already scheduled for ' + key);
      }
    }
  }

  onPositionUpdate(position = {}) {
    if (position.username && position.broker) {
      const key = position.username + '-' + position.broker;
      if (!this.onDemandRefreshUserTradesTimer[key]) {
        console.log(new Date() + ' | PositionUpdate: scheduling refresh user trades for ' + key);
        // Delaying the refresh operation to 3 seconds in order to control the flood of many updates within a second.
        this.onDemandRefreshUserTradesTimer[key] = setTimeout(() => {
          this.onDemandRefreshUserTradesTimer[key] = null;
          this.refreshUserTrades(position.username, position.broker, true);
        }, 3000);
      } else {
        console.log(new Date() + ' | PositionUpdate: ignoring refresh as its already scheduled for ' + key);
      }
    }
  }

  launchTradesModal(username, broker, clientID, allTrades, type) {
    this.setState({
      showTradesModal: true,
      selectedUsername: username,
      selectedBroker: broker,
      selectedClientID: clientID,
      allTrades: allTrades,
      selectedTradesType: type
    });
  }

  closeTradesModal() {
    this.setState({
      showTradesModal: false,
      selectedUsername: null,
      selectedBroker: null,
      selectedClientID: null,
      allTrades: {}
    });
  }

  launchComparePositionsModal(username, broker, clientID, allTrades) {
    this.setState({
      showComparePositionsModal: true,
      selectedUsername: username,
      selectedBroker: broker,
      selectedClientID: clientID,
      allTrades: allTrades
    });
  }

  closeComparePositionsModal() {
    this.setState({
      showComparePositionsModal: false,
      selectedUsername: null,
      selectedBroker: null,
      selectedClientID: null,
      allTrades: {}
    });
  }
  
  getTotalCapitalOfUserBroker(username, broker) {
    let totalCapital = 0;
    const userBrokerStrategies = this.getUserBrokerStrategies(username, broker);
    _.each(userBrokerStrategies, ubs => {
      if (config.isAllocationModelEnabled()) {
        if (ubs.strategyName === 'CAPITAL-DEPLOYED') {
          if (this.state.showIntraday && this.state.showPositional) {
            totalCapital = ubs.capital;
          } else if (this.state.showIntraday || this.state.showPositional) {
            const allocationModelName = this.getUserBrokerAllocationModel(username, broker);
            const allocationModel = _.find(this.state.allocationModels, am => am.name === allocationModelName);
            if (allocationModelName && allocationModel) {
              const numSets = ubs.capital / allocationModel.capital;
              totalCapital = numSets * (this.state.showIntraday ? allocationModel.intradayCapital : allocationModel.positionalCapital);
            }
          }
          return false; // break the loop
        }
      } else {
        if (!ubs.isOverlapCapital) {
          totalCapital += ubs.capital;
        }
      }
    });
    return totalCapital;
  }

  getExternalCapitalOfUserBroker(username, broker) {
    const userBrokerStrategies = this.getUserBrokerStrategies(username, broker);
    const externalStrategyIntra = _.find(userBrokerStrategies, ubs => ubs.strategyName === 'CAPITAL-EXTERNAL-INTRA');
    const externalStrategyPos = _.find(userBrokerStrategies, ubs => ubs.strategyName === 'CAPITAL-EXTERNAL-POS');
    let externalCapital = 0;
    if (externalStrategyIntra && this.state.showIntraday) {
      externalCapital += externalStrategyIntra.capital;
    }
    if (externalStrategyPos && this.state.showPositional) {
      externalCapital += externalStrategyPos.capital;
    }
    return externalCapital;
  }

  getRiskProfilesArray(ub = {}, wrtCmp = false) {
    const allTrades = this.getUserBrokerTrades(ub.username, ub.broker);
    const totalCapital = this.getTotalCapitalOfUserBroker(ub.username, ub.broker) 
      + this.getExternalCapitalOfUserBroker(ub.username, ub.broker);

    const riskProfiles = [];
    _.each(riskDistances, (rd, index) => {
      riskProfiles.push({
        index,
        rd,
        capital: 0,
        pnl: 0,
        returns: 0
      });
    });
    _.each(allTrades.brokerPositions, pos => {
      const risks = Utils.calculatePositionRisks(pos, riskDistances, wrtCmp);
      const indexSymbol = Utils.extractIndexSymbol(pos.tradingSymbol);
      _.each(riskDistances, (rd, index) => {
        riskProfiles[index].pnl += risks[index].pnl;
        if (indexSymbol) {
          if (!riskProfiles[index][indexSymbol]) {
            riskProfiles[index][indexSymbol] = 0;
          }
          riskProfiles[index][indexSymbol] += risks[index].pnl;
        }
      });
    });
    
    _.each(riskProfiles, riskProfile => {
      riskProfile.capital = totalCapital;
      riskProfile.returns = totalCapital > 0 ? Utils.roundOff(100 * riskProfile.pnl / totalCapital) : 0;
    });
    return riskProfiles;
  }

  getSummary() {
    let allTrades = [];
    let allAlgoPositions = [];
    let allBrokerPositions = [];

    _.each(this.state.usersBrokersList, ub => {
      const allUserTrades = this.getUserBrokerTrades(ub.username, ub.broker);
      if (_.isEmpty(allUserTrades) === false) {
        // consider active and completed trades only for calculating pnl
        const activeAndCompletedTrades = _.concat(allUserTrades.activeTrades, allUserTrades.completedTrades);
        allTrades = _.concat(allTrades, activeAndCompletedTrades);

        if (allUserTrades.algoPositions) {
          allAlgoPositions = _.concat(allAlgoPositions, allUserTrades.algoPositions);
        }
        if (allUserTrades.brokerPositions) {
          allBrokerPositions = _.concat(allBrokerPositions, allUserTrades.brokerPositions);
        }
      }
    });

    const strategiesSummary = {};
    _.each(this.state.strategiesSummary, (s, sName) => {
      if (s.product.startsWith('CAPITAL') ||
        (s.product === 'INTRADAY' && this.state.showIntraday) ||
        (s.product === 'POSITIONAL' && this.state.showPositional) ||
        (s.product === 'CASHBUY' && this.state.showCashBuy)) { 
        strategiesSummary[sName] = s;
        if (s.strategyName === 'CAPITAL-DEPLOYED') {
          if (this.state.showIntraday && this.state.showPositional) {
            // do nothing as total capital is considered
          } else if (this.state.showIntraday) {
            strategiesSummary[sName] = {
              ...strategiesSummary[sName],
              capital: s.intradayCapital
            }
          } else if (this.state.showPositional) {
            strategiesSummary[sName] = {
              ...strategiesSummary[sName],
              capital: s.positionalCapital
            }
          }
        } else if (s.strategyName === 'CAPITAL-EXTERNAL-INTRA' && !this.state.showIntraday) {
          delete strategiesSummary[sName];
        } else if (s.strategyName === 'CAPITAL-EXTERNAL-POS' && !this.state.showPositional) {
          delete strategiesSummary[sName];
        }
      }
    });

    return (<TradesSummaryComp 
      isAdmin={true}
      strategiesSummary={strategiesSummary}
      allTrades={allTrades}
      algoPositions={allAlgoPositions}
      brokerPositions={allBrokerPositions}
      riskDistances={riskDistances}
    />);
  }

  launchSquareOffModal(ub = {}, strategy) {
    this.setState({
      showSquareOffModal: true,
      selectedUsername: ub.username,
      selectedBroker: ub.broker,
      selectedStrategy: strategy
    });
  }

  closeSquareOffModal() {
    this.setState({
      showSquareOffModal: false,
      selectedUsername: null,
      selectedBroker: null,
      selectedStrategy: null
    });
  }

  launchBulkSquareOffConfirmationModal(product) {
    this.setState({
      showBulkSquareOffConfirmationModal: true,
      selectedProduct: product
    });
  }

  closeBulkSquareOffConfirmationModal() {
    this.setState({
      showBulkSquareOffConfirmationModal: false,
      selectedProduct: null
    });
  }

  squareOffBulk() {
    this.setState({
      showBulkSquareOffConfirmationModal: false,
      squareOffInProgress: true,
      squareOffSuccessMsg: null,
      squareOffErrorMsg: null
    });

    const data = {
      product: this.state.selectedProduct,
      strategy: 'ALL',
      broker: 'ALL'
    };

    HttpRequest.post({
      url: config.serverHost + "/apis/clientmanager/trades/squareoff",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }, (err, resp, respBody) => {
      console.log('squareOffBulk status code = ' + resp.statusCode);
      if (resp.statusCode === 200 || resp.statusCode === 201) {
        this.setState({
          squareOffSuccessMsg: JSON.parse(respBody).status,
          squareOffInProgress: false
        });
      } else if (resp.statusCode === 404) {
        this.setState({
          squareOffErrorMsg: 'squareOffBulk end point not found',
          squareOffInProgress: false
        });
      } else {
        console.error('squareOffBulk error => ', respBody);
        this.setState({
          squareOffErrorMsg: JSON.parse(respBody).error || JSON.parse(respBody).result,
          squareOffInProgress: false
        });
      }
    });
  }

  computeBrokerAlgoMismatch(ub, allTrades = {}) {
    let brokerAlgoQtyMismatch = false;
    let brokerAlgoSymbolMismatch = false;
    let brokerPosEmpty = false;

    if (allTrades.brokerPositions.length === 0) {
      // Can not calculate differences
      brokerPosEmpty = allTrades.algoPositions && allTrades.algoPositions.length > 0 ? true : false;
      return {
        brokerAlgoQtyMismatch,
        brokerAlgoSymbolMismatch,
        brokerPosEmpty
      };
    }
    
    _.each(allTrades.brokerPositions, bp => {
      if (ub.allocationModel === 'Broker Positional Only' && bp.productType !== 'NRML') {
        return; // ignore MIS positions for comparison
      }
      const algoPos = _.find(allTrades.algoPositions, ap => ap.tradingSymbol === bp.tradingSymbol && ap.productType === bp.productType);
      if (algoPos) {
        if (bp.netQty !== algoPos.netQty) {
          brokerAlgoQtyMismatch = true;
        }
      } else if (bp.netQty !== 0) {
        // corresponding algo position not found
        brokerAlgoSymbolMismatch = true;
      }
    });

    _.each(allTrades.algoPositions, ap => {
      if (ub.allocationModel === 'Broker Positional Only' && ap.productType !== 'NRML') {
        return; // ignore MIS positions for comparison
      }
      if (ap.sellQty > 0 || ap.buyQty > 0) {
        const brokerPos = _.find(allTrades.brokerPositions, bp => bp.tradingSymbol === ap.tradingSymbol && bp.productType === ap.productType);
        if (brokerPos) {
          if (ap.netQty !== brokerPos.netQty) {
            brokerAlgoQtyMismatch = true;
          }
        } else {
          // corresponding broker position not found
          brokerAlgoSymbolMismatch = true;
        }
      }
    });
    
    return {
      brokerAlgoQtyMismatch,
      brokerAlgoSymbolMismatch
    }
  }

  getUserBrokerDetails(ub = {}, sno) {
    const strategies = [];
    _.each(this.getUserBrokerStrategies(ub.username, ub.broker), ubs => {
      strategies.push({
        strategy: ubs.strategyName,
        capital: ubs.capital,
        lots: ubs.lots,
        trades: [],
        isOverlapCapital: ubs.isOverlapCapital,
        summary: {}
      });
    });

    const allTrades = this.getUserBrokerTrades(ub.username, ub.broker);
    if (_.isEmpty(allTrades)) {
      return null;
    }
    if (this.isAllStrategiesDisabled(ub.username, ub.broker)) {
      const totalTradeCount = allTrades.activeTrades.length + allTrades.openTrades.length + allTrades.completedTrades.length + allTrades.cancelledTrades.length;
      if (totalTradeCount === 0) {
        return null;
      }
    }
    // const brokerFunds = allTrades.brokerFunds || {};
    
    // conside active and completed trades only for calculating pnl
    const activeAndCompletedTrades = _.concat(allTrades.activeTrades, allTrades.completedTrades);
    _.each(activeAndCompletedTrades, trade => {
      const strategyData = _.find(strategies, s => s.strategy === trade.strategy);
      if (strategyData) {
        strategyData.trades.push(trade);
      }
    });

    let totalCapital = 0, externalCapital = 0;
    _.each(strategies, s => {
      s.summary = Utils.calculateTradesSummary(s.trades);
      s.summary.returnOnCapital = s.capital > 0 ? Utils.roundOff(s.summary.totalPL * 100 / s.capital) : 0;
      if (!s.isOverlapCapital) {
        totalCapital += s.capital;
      }
    });
    if (config.isAllocationModelEnabled()) {
      totalCapital = this.getTotalCapitalOfUserBroker(ub.username, ub.broker);
      externalCapital = this.getExternalCapitalOfUserBroker(ub.username, ub.broker);
      //console.log(ub.username + ' externalCapital = ' + externalCapital);
    }
    const netSummary = Utils.calculateTradesSummary(activeAndCompletedTrades);
    netSummary.returnOnCapital = totalCapital > 0 ? Utils.roundOff(netSummary.totalPL * 100 / totalCapital) : 0;

    //let algoPositionsPnl = 0;
    let brokerPositionsPnl = 0;
    /*_.each(allTrades.algoPositions, ap => {
      algoPositionsPnl += ap.totalPnl;
    });*/
    _.each(allTrades.brokerPositions, bp => {
      if (bp.productType === 'MIS' || bp.productType === 'NRML') {
        brokerPositionsPnl += bp.totalPnl;
      }
    });
    const totalCapitalPlusExternal = totalCapital + externalCapital;
    const returnOnCapitalByBrokerPositions = totalCapitalPlusExternal > 0 ? Utils.roundOff(brokerPositionsPnl * 100 / totalCapitalPlusExternal) : 0;
    
    /*let brokerPositionDiffByAlgoPositions = algoPositionsPnl !== 0 ? Utils.roundOff((brokerPositionsPnl - algoPositionsPnl) * 100 / algoPositionsPnl) : 0;
    brokerPositionDiffByAlgoPositions = Math.abs(brokerPositionDiffByAlgoPositions);*/
    
    const { brokerAlgoQtyMismatch, brokerAlgoSymbolMismatch, brokerPosEmpty } = this.computeBrokerAlgoMismatch(ub, allTrades);
    let misMatchText = allTrades.error ? allTrades.error : null;
    if (brokerPosEmpty) {
      if (this.state.progressCompleted) {
        misMatchText = 'No Broker Pos';
      } else {
        misMatchText = 'Loading...'; // Broker positions not yet fetched
      }
    }
    if (brokerAlgoQtyMismatch) {
      misMatchText = 'Qty Mis';
    }
    if (brokerAlgoSymbolMismatch) {
      if (misMatchText) {
        misMatchText = misMatchText + ' & Symbol Mis'; 
      } else {
        misMatchText = 'Symbol Mis';
      }
    }
    
    const allTradesArray = [];
    allTradesArray.push({key: 'openTrades', trades: allTrades.openTrades});
    allTradesArray.push({key: 'activeTrades', trades: allTrades.activeTrades});
    allTradesArray.push({key: 'completedTrades', trades: allTrades.completedTrades});
    allTradesArray.push({key: 'cancelledTrades', trades: allTrades.cancelledTrades});
    
    const rowKey = 'user-' + ub.username.replace(/\./g, '') + ub.broker;
    // const brokerUtilizedMargin = Utils.formatNumberToCommaSeparated(brokerFunds.utilizedAmount || 0, false);
    // const brokerTotalMargin = Utils.formatNumberToCommaSeparated(brokerFunds.totalBalance || 0, false);
    const loadingInProgress = this.state.fetchingUserTradesInProgress[`${ub.username}-${ub.broker}`] ? true : false;
    if (loadingInProgress) {
      misMatchText = 'Loading...';
    }

    const riskProfiles = this.getRiskProfilesArray(ub, this.state.riskProfileWrtCmp);
    
    return (
      <tr key={rowKey}>
        <td>{sno}</td>
        <td>{ub.username}</td>
        <td>{Utils.formatNumberToCommaSeparated(totalCapital, false)}</td>
        <td>{Utils.formatNumberToCommaSeparated(externalCapital, false)}</td>
        <td className="number-right">
          <Button color="link" style={{padding: 0}} onClick={() => this.launchUserPnlModal(strategies, ub)}>
            <span className={netSummary.totalNetPL > 0 ? "number-right number-pos" : "number-right number-neg"}>
              {Utils.formatNumberToCommaSeparated(netSummary.totalPL, false)}
            </span>
          </Button>
        </td>
        <td className={netSummary.totalNetPL > 0 ? "number-right number-pos" : "number-right number-neg"}>
          {netSummary.returnOnCapital} %
        </td>
        <td className={brokerPositionsPnl > 0 ? "number-right number-pos" : "number-right number-neg"}>
          {Utils.formatNumberToCommaSeparated(brokerPositionsPnl, false)}
        </td>
        <td className={brokerPositionsPnl > 0 ? "number-right number-pos" : "number-right number-neg"}>
          {returnOnCapitalByBrokerPositions} %
        </td>
        <td>
          <Button color="link" style={{padding: 0}}
            onClick={() => this.launchComparePositionsModal(ub.username, ub.broker, ub.clientID, allTrades)}>
            <span className={misMatchText ? "text-danger" : "text-muted"}>
              {misMatchText ? misMatchText : 'View'}
            </span>
          </Button>
        </td>
        <td>
          <Button color="primary"
            onClick={() => this.launchSquareOffModal(ub)}>
            Square Off
          </Button>
        </td>
        <td>
          {
            _.map(allTradesArray, (entry, index) => {
              const type = _.capitalize(entry.key.substring(0, entry.key.length - "Trades".length)) + ' Trades';
              const displayText = `${type[0]}(${entry.trades.length}) `;
              return (<Button key={entry.key}
                color="link" style={{padding: 0}}
                onClick={() => this.launchTradesModal(ub.username, ub.broker, ub.clientID, allTrades, type)}
                >
                {displayText}
              </Button>);
            })
          }
        </td>
        <td>
          <Button id={rowKey + '-risk'} color="link" style={{padding: 0}} onClick={() => this.toggleRiskPopover(rowKey + '-risk', riskProfiles, ub)}>
            <span className={riskProfiles[0].pnl < 0 ? 'number-neg' : 'number-pos'}>{Utils.formatNumberToCommaSeparated(riskProfiles[0].pnl)} ({riskProfiles[0].returns}%)</span>
            <span> .... </span>
            <span className={riskProfiles[riskProfiles.length - 1].pnl < 0 ? 'number-neg' : 'number-pos'}>{Utils.formatNumberToCommaSeparated(riskProfiles[riskProfiles.length - 1].pnl)} ({riskProfiles[riskProfiles.length - 1].returns}%)</span>
          </Button>
        </td>
        <td style={{'textAlign': 'center'}}>
          <i onClick={() => this.refreshUserTrades(ub.username, ub.broker, true, true)} 
            className={loadingInProgress ? "fa fa-refresh fa-spin" : "fa fa-refresh"} 
            style={{'fontSize': '20px', 'cursor': 'pointer'}}>
          </i>
        </td>
      </tr>
    );
  }

  launchUserPnlModal(strategies, ub) {
    this.setState({
      showUserPnlModal: true,
      selectedUserStrategies: strategies,
      selectedUserBroker: ub
    });
  }

  closeUserPnlModal() {
    this.setState({
      showUserPnlModal: false,
      selectedUserStrategies: null,
      selectedUserBroker: null
    });
  }

  renderUserPnlModal() {
    const ub = this.state.selectedUserBroker;
    const strategies = _.filter(this.state.selectedUserStrategies, s => !s.strategy.startsWith('CAPITAL'));
    strategies.sort((s1, s2) => {
      return Utils.getStrategyDisplayOrder(s1.strategy) - Utils.getStrategyDisplayOrder(s2.strategy);
    });
    return (<Modal isOpen={this.state.showUserPnlModal} size="lg">
      <ModalHeader toggle={this.closeUserPnlModal}>{ub.username + '-' + ub.broker} : Strategy wise profit and loss</ModalHeader>
      <ModalBody>
        <div style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
          <Table className="no-wrap v-middle" size="sm" responsive>
            <thead style={{ position: "sticky", top: "0" }}>
              <tr className='border-0'>
                <th className='border-0'>Strategy</th>
                <th className='border-0'>Product</th>
                <th className='border-0 number-right'>Capital</th>
                <th className='border-0 number-right'>Profit/Loss</th>
                <th className='border-0'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                _.map(strategies, s => {
                  return (<tr key={s.strategy}>
                    <td>{Utils.getStrategyDisplayName(s.strategy)}</td>
                    <td>{Utils.getStrategyProduct(s.strategy)}</td>
                    <td className="number-right">{Utils.formatNumberToCommaSeparated(s.capital, false)}</td>
                    <td className={s.summary.totalNetPL > 0 ? "number-right number-pos" : "number-right number-neg"}>
                      {Utils.formatNumberToCommaSeparated(s.summary.totalNetPL, true)} ({Utils.formatNumberToCommaSeparated(s.summary.returnOnCapital, true)} %) 
                    </td>
                    <td>
                      <Button color="warning"
                        onClick={() => this.launchSquareOffModal(this.state.selectedUserBroker, s.strategy)}>
                        Square Off
                      </Button>
                    </td>
                  </tr>);
                })
              }
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>);
  }

  toggleRiskPopover(targetId, riskProfiles = [], ub) {
    //console.log('targetId = ' + targetId);
    const isRiskPopoverOpen =  this.state.isRiskPopoverOpen ? false : true;
    this.setState({
      riskPopoverTargetId: targetId,
      isRiskPopoverOpen: isRiskPopoverOpen,
      selectedRiskProfiles: riskProfiles,
      selectedUserBroker: ub
    });
  }

  renderRiskPopover() {
    return this.state.isRiskPopoverOpen && this.state.riskPopoverTargetId ? (<Popover placement="bottom" 
      isOpen={this.state.isRiskPopoverOpen ? true : false} 
      target={this.state.riskPopoverTargetId} 
      toggle={this.toggleRiskPopover}>
      <PopoverHeader>
        <span>Risk Profile</span>
        <div className="filter-inputs">
          <div className="filter-input">
            <label>Only active positions w.r.t. CMP</label>
            <input
              type="checkbox"
              checked={this.state.riskProfileWrtCmp}
              onChange={this.toggleRiskProfileWrtCmp}></input>
          </div>
        </div>        
      </PopoverHeader>
      <PopoverBody>
        <Table className="no-wrap v-middle" size="sm" responsive>
          <thead>
            <tr className='border-0'>
              <th key="indexMovement" className='border-0'>
                INDEX MOVE
              </th>
              {
                _.map(riskDistances, rd => {
                  return (<th key={rd} className='border-0'>
                    {rd}%
                  </th>);
                })
              }
            </tr>
          </thead>
          <tbody>
            <tr>
              <td key="total">
                TOTAL
              </td>
              {
                _.map(this.state.selectedRiskProfiles, (riskProfile, index) => {
                  return (<td key={index} className={riskProfile.pnl < 0 ? 'number-neg' : 'number-pos'}>
                    {Utils.formatNumberToCommaSeparated(riskProfile.pnl)} [{riskProfile.returns}%]
                  </td>);
                })
              }
            </tr>
            {
              _.map(['NIFTY', 'BANKNIFTY', 'FINNIFTY'], symbol => {
                return (<tr>
                  <td key={symbol}>
                    {symbol}
                  </td>
                  {
                    _.map(this.state.selectedRiskProfiles, (riskProfile, index) => {
                      if (!riskProfile[symbol]) {
                        return (<td key={index}>0</td>);
                      } 
                      return (<td key={index} className={riskProfile[symbol] < 0 ? 'number-neg' : 'number-pos'}>
                        {Utils.formatNumberToCommaSeparated(riskProfile[symbol])}
                      </td>);
                    })
                  }
                </tr>);
              })
            }
          </tbody>
        </Table>
      </PopoverBody>
    </Popover>) : null;
  }

  renderProgressBar() {
    const completed = _.keys(this.state.userBrokerToTradesMap).length;
    const total = this.state.usersBrokersList.length;
    if (completed === total) {
      if (this.progressBarTimer) {
        return null; // Do not render progress bar after timeout
      }
      this.progressBarTimer = setTimeout(() => {
        this.setState({
          progressCompleted: true
        }, () => {
          this.refreshUserTradesAsync();
          this.startRefreshUserTradesTimer();
          this.startRefreshDataRealTimeTimer();
        });
      }, 100);
    }
    return (<div style={{'marginBottom': '5px'}}>
      <div className="text-center">Loaded trades of users {completed} of {total}</div>
      <Progress value={completed} max={total} />
    </div>);
  }

  launchAlterTradesBulkModal() {
    let bulkTrades = [];
    _.each(this.state.userBrokerToTradesMap, value => {
      // currently considering only active trades
      bulkTrades = _.concat(bulkTrades, value.activeTrades);
    });
    this.setState({
      showAlterTradesBulkModal: true,
      bulkTrades
    });
  }

  closeAlterTradesBulkModal() {
    this.setState({
      showAlterTradesBulkModal: false,
      bulkTrades: []
    });
  }

  sortUserBrokersList(userBrokersList = []) {
    const sortedList = [];
    _.each(userBrokersList, ub => {
      const allTrades = this.getUserBrokerTrades(ub.username, ub.broker);
      if (_.isEmpty(allTrades)) {
        sortedList.push({
          ...ub,
          mismatch: false,
          hasActiveTrades: false
        });
      } else {
        const { brokerAlgoQtyMismatch, brokerAlgoSymbolMismatch, brokerPosEmpty } = this.computeBrokerAlgoMismatch(ub, allTrades);
        sortedList.push({
          ...ub,
          mismatch: brokerAlgoQtyMismatch || brokerAlgoSymbolMismatch || brokerPosEmpty ? true : false,
          hasActiveTrades: allTrades.activeTrades.length > 0 ? true : false
        });
      }
    });

    sortedList.sort((ub1, ub2) => {
      // show mismatch records on top
      if (ub1.mismatch === ub2.mismatch) {
        if (ub1.hasActiveTrades === ub2.hasActiveTrades) {
          return this.getTotalCapitalOfUserBroker(ub2.username, ub2.broker) - this.getTotalCapitalOfUserBroker(ub1.username, ub1.broker);
        } else {
          return ub2.hasActiveTrades ? 1 : -1;
        }
      }
      return ub2.mismatch ? 1 : -1;
    });

    return sortedList;
  }

  renderActionsCard(usersBrokersList) {
    return (<Card>
      <CardBody>
        {!this.state.fetchingUsersInProgress && !this.state.error && usersBrokersList.length > 0 && <div>
          <Button size="md" 
            style={{'margin-right': '10px'}}
            color="danger"
            disabled={!this.state.progressCompleted || this.state.fetchingUsersInProgress}
            onClick={() => this.launchBulkSquareOffConfirmationModal('ALL')}
            >SquareOff Everything
          </Button>
          <Button size="md" 
            style={{'margin-right': '10px'}}
            color="warning"
            disabled={!this.state.progressCompleted || this.state.fetchingUsersInProgress}
            onClick={() => this.launchBulkSquareOffConfirmationModal('INTRADAY')}
            >SquareOff Intraday
          </Button>
          <Button size="md" 
            style={{'margin-right': '10px'}}
            color="warning"
            disabled={!this.state.progressCompleted || this.state.fetchingUsersInProgress}
            onClick={() => this.launchBulkSquareOffConfirmationModal('POSITIONAL')}
            >SquareOff Positional
          </Button>
          <Button size="md"
            color="dark"
            disabled={!this.state.progressCompleted || this.state.fetchingUsersInProgress}
            onClick={this.launchAlterTradesBulkModal.bind(this)}
            >Alter Trades
          </Button>
          <div style={{'margin-top': '7px'}}>
            {this.state.squareOffInProgress && <UncontrolledAlert color='primary'>Square off initiate request is in progress...</UncontrolledAlert>}
            {this.state.squareOffSuccessMsg && <UncontrolledAlert color='success'>{this.state.squareOffSuccessMsg}</UncontrolledAlert>}
            {this.state.squareOffErrorMsg && <UncontrolledAlert color='danger'>{this.state.squareOffErrorMsg}</UncontrolledAlert>}
          </div>
        </div>}
      </CardBody>
    </Card>);
  }

  toggleIntraday() {
    this.setState({
      showIntraday: !this.state.showIntraday
    });
  }

  togglePositional() {
    this.setState({
      showPositional: !this.state.showPositional
    });
  }

  toggleCashBuy() {
    this.setState({
      showCashBuy: !this.state.showCashBuy
    });
  }

  toggleRiskProfileWrtCmp() {
    const riskProfileWrtCmp = !this.state.riskProfileWrtCmp;
    this.setState({
      riskProfileWrtCmp,
      selectedRiskProfiles: this.getRiskProfilesArray(this.state.selectedUserBroker, riskProfileWrtCmp)
    });
  }

  renderHeaderContent() {
    return (<div className="filter-inputs">
      <div className="filter-input">
          <label><b>Show Intraday</b></label>
          <input
            type="checkbox"
            checked={this.state.showIntraday}
            onChange={this.toggleIntraday}></input>
        </div>
        <div className="filter-input">
          <label><b>Show Positonal</b></label>
          <input
            type="checkbox"
            checked={this.state.showPositional}
            onChange={this.togglePositional}></input>
        </div>
        {config.isAutoQuant() && <div className="filter-input">
          <label><b>Show CashBuy</b></label>
          <input
            type="checkbox"
            checked={this.state.showCashBuy}
            onChange={this.toggleCashBuy}></input>
        </div>}
    </div>);
  }

  render() {
    const usersBrokersList = this.sortUserBrokersList(this.state.usersBrokersList);
    return (<div>
      <h5>Trades & Positions</h5>
      {this.renderHeaderContent()}
      {this.getSummary()}
      {this.renderActionsCard(usersBrokersList)}
      <Card>
        <CardBody>
          <div>
            {this.state.fetchingUsersInProgress === true && <div>
              Fetching users in progress...
            </div>}
            {this.state.error && <div className='text-orange'>
              {this.state.error}
            </div>}
            {!this.state.fetchingUsersInProgress && !this.state.error
              && usersBrokersList.length > 0 ? this.renderProgressBar()
              : null}
            {!this.state.fetchingUsersInProgress && !this.state.error 
              && usersBrokersList.length === 0 && <div>
              No user trades found.
            </div>}
            {!this.state.fetchingUsersInProgress && !this.state.error 
              && usersBrokersList.length > 0 && <div>
              <Table className="no-wrap v-middle" size="sm" responsive>
                <thead>
                  <tr className="border-0">
                    <th className="border-0">SNo</th>
                    <th className="border-0">Username</th>
                    <th className="border-0">Capital</th>
                    <th className="border-0">Ext Capital</th>
                    <th className="border-0 number-right">Algo Pnl</th>
                    <th className="border-0 number-right">Algo %</th>
                    <th className="border-0 number-right">Broker Pnl</th>
                    <th className="border-0 number-right">Broker %</th>
                    <th className="border-0">Positions</th>
                    <th className="border-0">Square Off</th>
                    <th className="border-0">Trades</th>
                    <th className="border-0">{`Risk Profile % (${riskDistances.join()})`}</th>
                    <th className="border-0">Refresh</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(usersBrokersList, (ub, index) => {
                      return this.getUserBrokerDetails(ub, index + 1);
                    })
                  }
                </tbody>
              </Table>
            </div>}
          </div>
        </CardBody>
      </Card>
      {this.state.showTradesModal && <TradesModal  
        isModalOpen={this.state.showTradesModal}
        onCancel={this.closeTradesModal}
        username={this.state.selectedUsername}
        broker={this.state.selectedBroker}
        clientID={this.state.selectedClientID}
        allTrades={this.state.allTrades}
        type={this.state.selectedTradesType}
      />}
      {this.state.showComparePositionsModal && <ComparePositionsModal
        isModalOpen={this.state.showComparePositionsModal}
        onCancel={this.closeComparePositionsModal}
        username={this.state.selectedUsername}
        broker={this.state.selectedBroker}
        clientID={this.state.selectedClientID}
        algoPositions={this.state.allTrades.algoPositions}
        brokerPositions={this.state.allTrades.brokerPositions}
        fetchAllTrades={(username, broker) => this.refreshUserTrades(username, broker, true)}
      />}
      {this.state.showSquareOffModal && <SquareOffModal
        isModalOpen={this.state.showSquareOffModal}
        onCancel={this.closeSquareOffModal}
        username={this.state.selectedUsername}
        broker={this.state.selectedBroker}
        strategy={this.state.selectedStrategy}
      />}
      {this.state.showAlterTradesBulkModal && <AlterTradesBulkModal
        isModalOpen={this.state.showAlterTradesBulkModal}
        trades={this.state.bulkTrades}
        onCancel={this.closeAlterTradesBulkModal.bind(this)}
        allowedAlterOperations="completeTradeBulk"
      />}
      {this.state.showBulkSquareOffConfirmationModal && <ConfirmationModal
        isModalOpen={this.state.showBulkSquareOffConfirmationModal}
        title="Bulk Square off"
        message={"Are you sure you want to square off all " +  (this.state.selectedProduct === 'ALL' ? '' : this.state.selectedProduct) + " trades of all users?"}
        onConfirm={() => this.squareOffBulk()}
        onCancel={this.closeBulkSquareOffConfirmationModal}
        />}
      {this.state.showUserPnlModal && this.renderUserPnlModal()}
      {this.renderRiskPopover()}
    </div>);
  }
}

export default UsersAllTradesStatus;